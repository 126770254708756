<style lang="scss" scoped>
    @import './product-brands-winners.modal';

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  width: 80vw;
  height: 105vh;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.6em;
}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.brands-modal__product-title-wrapper {
  padding: 2em;
}
.brands-modal__product-title {
  font-weight: 400;
  color: #606060;
  line-height: 22px;
  font-size: 1.8em;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.brands-modal__actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
}
.page-table-container {
  margin: 2em;
  max-height: 50%;
  overflow: auto;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  align-items: center;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
  text-align: left;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.brand-width {
  flex: 1;
}
.request-width{
  width: 15%;
}
.action-width {
  width: 15%;
  display: flex;
  align-items: center;
}
.id-width {
  width: 15%;
}
.edit-icon {
  cursor: pointer;
}
.delete-icon {
  color: #CB595B;
  cursor: pointer;
  margin-left: 10px;
}
.brand-button {
  margin-top: 2.4em;
}
.new-brand-container {
  padding: 2em;
}
.new-brand__title-wrapper {
  margin-bottom: 1em;
}
.new-brand__title {
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-size: 1.7em;
  color: #707070;
}
.brands-modal__actions-search{
  width: 43%;
}
.new-brand__back-button {
  display: flex;
  justify-content: end;
}
.brands-modal__generic-request{
  width: 30%;
  color: red;
  background-color: rgb(240, 204, 204);
  font-size: 1.6em;
  padding: 1vh 1vw;
  border-radius: 5px;
  font-weight: bold;
}
.brands-modal__objective-request{
  width: 30%;
  color: rgb(46, 46, 248);
  background-color: lightblue;
  font-size: 1.6em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.brands-modal__total-request{
  width: 30%;
  color: rgb(197, 129, 2);
  background-color: lightgoldenrodyellow;
  font-size: 1.6em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.boxes-icon {
  position: relative;
  top: -1px;
  width: 1.3em;
  margin-left: 10px;
  cursor: pointer;
}
.products-page__input-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__newbrand_input {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px;
  padding: 0.8em;
  width: 30vw;
  height: 7vh;
}
.products-page__newbrand_input:focus {
  border: 1px solid #898989 !important;
}
.products-page__input-icon {
  position: relative;
  left: -35px;
  border-radius: 0px 10px 10px 0px;
  color: #8F8F8F;
  border: none;
}
.add-icon {
  position: relative;
  top: 3px;
}
.products-page__selects {
  width: 38%;
}
.checkbox-icon {
  cursor: pointer;
}
.new-brand__input {
  width: 35% !important;
}
.settings-icon {
  color: #FF7110;
  cursor: pointer;
  margin-left: 10px;
}

/deep/ 
.products-page__input {
  padding: 25px 10px !important;
  color: #707070;
  font-size: 1.2em;
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .modal-content-container{width: 100vw;}
}
@media only screen and (max-width: 600px) {
  .page-table-content{font-size: 1.5em;}
}
@media only screen and (max-width: 450px) {
  .modal-content-container{width: 115vw;}
  .request-width{width: 30%;}
  .brands-modal__actions-wrapper{
    flex-direction: column;
    gap: 1vh;
  }
  .brands-modal__actions-search{width: 100%;}
  .brand-button{width: 100%;}
  .brands-modal__generic-request{
    width: 100%;
    font-size: 2.5em;
    text-align: center;
  }
  .brands-modal__objective-request{
    width: 100%;
    font-size: 2.5em;
    text-align: center;
  }
  .brands-modal__total-request{
    width: 100%;
    font-size: 2.5em;
    text-align: center;
  }
}


</style>

<template>
  
  <div class="modal-mask">
    <cc-loader-full v-if="isLoading"/>
    <div class="modal-content-container">
      <div class="new-product__header-container">
        <span class="new-product__header-title">
          Marcas
        </span>
        <span @click="close()" class="material-icons new-product__closebtn-icon">
          cancel
        </span>
      </div>
      <div v-if="actionSearch" class="brands-modal__product-title-wrapper">
        <span v-if="!showProductsTable" class="brands-modal__product-title">
          #{{ product.name }}
          <span v-if="!product.pro_padrao_id" @click="showEditProPadraoNullModal(product)" class="material-icons-outlined settings-icon">
            settings
          </span>
        </span>
        <span v-if="!showProductsTable" class="brands-modal__product-title">
          Pro Padrão: {{ product.pro_padrao_id || '-' }} 
        </span>
        <span v-if="showProductsTable" class="brands-modal__product-title">Marca: {{ brandNameValue }}</span>
      </div>
      <div v-if="actionSearch" class="brands-modal__actions-wrapper">
        <StandardSearch v-if="!showProductsTable && !showAddNewBrand"
                        class="brands-modal__actions-search" 
                        v-model="filters.name" 
                        :label="'Marca'" 
                        :placeholder="'Buscar marca'"/>
        <cc-search v-if="showAddNewBrand"
                   class="new-brand__input"
                   :placeholder="'Digite a nova marca'" 
                   :icon="'fa success fa-plus'" 
                   v-model="new_brand" 
                   @search="save()" /> 
        <StandardInputAuto v-if="showProductsTable && !showAddNewBrand"
                           :placeholder="'Digite o produto'"
                           :title="'Buscar produtos'"
                           :action="setProductValue"
                           class="products-page__selects"
                           :type="'text'"/>
        <div v-if="!showAddNewBrand && !showProductsTable" class="new-brand__back-button">
          <Button :iconName="'add'" title="Adicionar Marca" :text="'Adicionar Marca'" :action="() => { showAddNewBrand = true }"/>
        </div>
        <div v-if="showAddNewBrand && !showProductsTable" class="new-brand__back-button">
          <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => { showAddNewBrand = false }"/>
        </div>
        <div v-if="showProductsTable && !showAddNewBrand" class="new-brand__back-button">
          <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => { showProductsTable = false }"/>
        </div>
      </div>
      <div v-if="actionSearch && !showProductsTable && !showAddNewBrand" class="page-table-container">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text id-width">ID Padrão</div>
            <div class="page-table-header-text brand-width">Marca</div>
            <div class="page-table-header-text action-width">Ações</div>
          </div>
          <div v-for="(product, idx) in brands" 
               :key="idx"
               :class="getSpecialBackground(idx)">
            <div class="page-table-row">
              <div class="page-table-desc-column id-width">{{ product.pro_padrao_id || '-' }}</div>
              <div class="page-table-desc-column brand-width">{{ product.brand }}</div>
              <div class="page-table-desc-column action-width">
                <span @click="delete_product(product)" class="material-icons-outlined delete-icon">
                  delete
                </span>
                <div v-if="product.pro_padrao_id == null">
                   <img class="boxes-icon" 
                        @click="showProductsList(product)" 
                        title="Ver Marcas" 
                        src="@/assets/custom-boxes-icon.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showProductsTable" class="page-table-container">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text id-width">ID</div>
            <div class="page-table-header-text brand-width">Produto</div>
            <div class="page-table-header-text action-width">Ação</div>
          </div>
          <div v-for="(product, idx) in productsList" 
               :key="idx"
               :class="getSpecialBackground(idx)">
            <div class="page-table-row">
              <div class="page-table-desc-column id-width">{{ product.id }}</div>
              <div class="page-table-desc-column brand-width">{{ product.brand }}</div>
              <div class="page-table-desc-column action-width">
                <span v-if="product.isChecked"
                      @click="removeBrandsCode(product)"
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;">
                  check_box
                </span>
                <span v-else
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;"
                      @click="storeBrandsCode(product)">
                  check_box_outline_blank
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditProductBrandsModal v-if="showEditBrandsModal"
                            :brandId="brandProductId"
                            @reload="reload"
                            @close="showEditBrandsModal = false"/>
    <EditProPadraoNullModal id="modal"
                            v-if="showEditProPadraoNull"
                            :product="productProPadraoNull"
                            :pro_padrao_is_null="pro_padrao_is_null"
                            @close="showEditProPadraoNull = false; productProPadraoNull = null;"/>
  </div>
</template>

<script>
import * as CONSTANTS from '@/constants/constants';
import ProductService from '@/services/v3/management/product.service';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardSearch from '@/components/ui/inputs/StandardSearch.vue'
import Button from '@/components/ui/buttons/StandardButton.vue'
import EditProductBrandsModal from './edit-product-brands-winners.modal.vue'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue';
import EditProPadraoNullModal from "@/modals/product-generic-zero-brands/product-generic-zero-brands.modal";

export default {
    mixins: [ loaderMixin ],
    props: {
        product: {
            type: Object,
            required: true
        },
        product_pattern: {
            type: Boolean,
            required: true
        },
        reloadBrands: {
          type: Function
        },
        updateBrandsModal: {
          type: Number
        }
    },
    components: { 
      StandardSearch,
      Button,
      EditProductBrandsModal,
      StandardInputAuto,
      EditProPadraoNullModal,
    },
    data() {
        return {
            svc: new ProductService(),
            modal: {
                title: `Marcas #${this.product.name}`,
                icon_title: 'fa fa-list',
                cancel_text: 'Voltar',
                subtitle: "Acompanhe as marcas disponíveis para seus produtos",
                style: {
                    width: "65%"
                }
            },
            filters: {
                name: ""
            },
            actionSearch: true,
            new_brand: null,
            sucess_brand: false,
            erro_brand: false,
            total: undefined,
            objective: undefined,
            generic: undefined,
            isLoading: true,
            total_items: 0,
            brands: [],
            showEditBrandsModal: false,
            brandProductId: {
              id: undefined,
            },
            showProductsTable: false,
            productsList: [],
            newProdutoPadraoValue: "",
            brandNameValue: "",
            brandIdValue: undefined,
            showAddNewBrand: false,
            showEditProPadraoNull: false,
            productProPadraoNull: undefined,
            pro_padrao_is_null: undefined,
        }
    },
    watch: {
      product() {
        this.brands = this.filtred_brands()
      },
      updateBrandsModal() {
        this.brands = this.filtred_brands()
      },
    },
    methods: {
        showEditProPadraoNullModal(value) {
          this.productProPadraoNull = value;
          this.pro_padrao_is_null = true;
          this.showEditProPadraoNull = true;
        },
        storeBrandsCode(product) {
          this.productsList.forEach(product => product.isChecked = false)

          this.updateProPadraoCode(product)

          if(product.isChecked == undefined || product.isChecked == false) {
            product.isChecked = true
            this.newProdutoPadraoValue = product.id
          }
          product.isChecked = true
          this.$forceUpdate()
        },
        removeBrandsCode(product) {
          product.isChecked = false
          this.$forceUpdate()
        },
        updateProPadraoCode(product) {
          this.confirm_action({
            message: `Deseja atualizar o Pro Padrão da marca ${this.brandNameValue} para ${product.id}?`,
            callback: () => {
              const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
              }
              const params = {
                pro_id: this.brandIdValue,
                pro_padrao_id: product.id,
              }
              axios
                .put(`${CONSTANTS.API_V3_URL}/admin/products/`, params, config)
                .then(res => {
                  this.$swal.fire('Configurações Salvas')
                  this.showProductsTable = false;
                  this.reloadBrands(this.product)
              });
            }
          })
        },
        showProductsList(brand) {
          this.brandIdValue = brand.id
          this.brandNameValue = brand.brand

          this.isLoading = true
          this.showProductsTable = true;

          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            params: {}
          };
          axios
            .get(`${CONSTANTS.API_V3_URL}/admin/management/products?city=&fu=&start_at=&page=1&id=${this.product.pro_padrao_id}`, config)
            .then(data => {
              this.productsList = data.data.data[0].derivatives;
              this.isLoading = false
          })
            .catch((err) => {
              this.$swal.fire(`Não foram encontradas marcas no produto ${ this.product.name }.`);
              this.isLoading = false;
              this.showProductsTable = false;
            })
        },
        editBrandName(product) {
          this.brandProductId = product.id
          this.showEditBrandsModal = true;
        },
        setProductValue(value){
		      this.filters.name = value;
	    	},
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        close() {
            if(this.actionSearch){
                this.$emit('close')
            }else{
                this.actionSearch = !this.actionSearch;
            }
        },
        toogleAtivoMarcas(index){
            this.brands[index].pro_status = +!this.brands[index].pro_status

            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                produto_id: this.brands[index].pro_id,
                status: this.brands[index].pro_status
                }
            }
            let url = `${CONSTANTS.API_URL}/inativarativarprodutocliente`
            axios.post(url,{},config)
            .then(()=>{})
        },
        save(){
            this.svc.add_brand(this.product.id, this.new_brand, this.product_pattern)
            .then(response => response.data).then((data)=>{
                this.new_brand = null;
                this.product.derivatives.push(data);
                this.actionSearch = true
                this.showAddNewBrand = false;
                this.$forceUpdate()
                this.showMessageSucess();
                this.reloadBrands(this.product)
            }).catch(error => {
                this.showMessageErro();
			})
        },
        showMessageSucess(){
            const notification = {
                type: 'sucess',
                message: 'Marca Cadastrada com sucesso!'
            }
            this.$store.dispatch('notification/add', notification);
        },
        showMessageErro(){
            const notification = {
                type: 'error',
                message: 'Não foi possível salvar a marca!'
            }
            this.$store.dispatch('notification/add', notification);
        },
        delete_product(product){
            let message = (this.product_pattern)? `Produto será excluído da base padrão!` : `Produto será excluído da base!`
            this.confirm_action({
				message: "Confirma a remoção do produto?",
				subtitle: message,
				callback: () => {
					this.svc.delete(product.id)
                    .then(()=>{
                        this.product.derivatives = this.product.derivatives.filter(d => d.id != product.id);
                        this.$forceUpdate();
                        this.present_success("Excluído!", "Produto excluído com sucesso");
                        this.reloadBrands(this.product)
                    }).catch(error => {
                        ErrorHandlerService.handle( error, this.$store );
                    })
				}
			})
        },
        filtred_brands(){
            return this.product.derivatives?.filter(deriv =>{
                return deriv.brand.toLowerCase().includes(this.filters.name?.toLowerCase())
            })
        }
    },
    mounted() {
      this.brands = this.filtred_brands()
        this.svc.getBrands(this.product.id).then(resp => {
          this.total = resp.data.total
          this.objective = resp.data.totalObjetivo
          this.generic = resp.data.totalGenerico
          for (let index = 0; index < resp.data.data.length; index++) {
            const respBrand = resp.data.data[index];
            for (let index2 = 0; index2 < this.brands.length; index2++) {
              const brand = this.brands[index2];
              if (respBrand.pro_observacao == brand.brand){brand.resq = respBrand.qtd}
            }
          }
          this.isLoading = false
        })
    }
}
</script>
