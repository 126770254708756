<template>
    <!-- <div>
        <div class="monitors-winners">
            <div class="body">
                <div class="container-fluid">
                <div class="row contents">
                    <div class="col-lg-12 box">
                        <div class="row not-print">
                            <div class="col-3">
                                <h4 class="header-title">
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                    Comparativo de Preços
                                </h4>
                                <h5 class="header-subtitle">Cotação #{{quotationId}}</h5>
                            </div>
                            <div class="col-9">
                                <form v-on:submit="filter()" v-on:submit.prevent class="pb-4 form-inline filters">
                                    <a id="download-excel" style="display:none;"></a>
                                    <div class="form-group pr-3" v-if="is_open('PRODUCTS')">
                                    <label for="" class="label-control">Descrição do Produto</label>
                                    <cc-search
                                        @change="update"
                                        v-model="description" type="number" />
                                    </div>
                                    <div class="form-group pr-3" v-if="is_open('PRODUCTS')">
                                        <label for="" class="label-control">Fornecedor</label>
                                        <cc-select-v2
                                            :tracker="'value'"
                                            :text="'name'"
                                            :options="filter_by_providers_options"
                                            @select="update"
                                            v-model="filters.provider" />
                                    </div>
                                    <div class="form-group pr-3" v-if="is_open('PRODUCTS')">
                                        <label for="" class="label-control">Categoria</label>
                                        <cc-select-v2
                                            :tracker="'value'"
                                            :text="'name'"
                                            :options="filter_by_category_options"
                                            v-model="filters.category" @onselect="(val) => {
                                                filters.category = val
                                                update()
                                            }" />
                                    </div>
                                    <div class="form-group pr-3" v-if="is_open('PRE-ORDERS') || is_open('ORDERS')">
                                        <label for="" class="label-control">Empresas</label>
                                        <cc-select-v2
                                            :tracker="'value'"
                                            @select="update"
                                            :text="'name'"
                                            :options="filter_by_sellers_options"
                                            v-model="filters.store" />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="pt-5 info-and-actions not-print">
                            <div class="info">
                                <cc-button
                                    :content="'Produtos'"
                                    :classes="{ active: is_open('PRODUCTS'), fixed: true }"
                                    @click="open('PRODUCTS')"
                                    v-if="can('REQUEST', 'READ_ALL_SUBREQUESTS')"
                                ></cc-button>
                                <cc-button
                                    v-if="total_pre_orders > 0"
                                    :content="can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pré-pedidos: ${to_currency(total_pre_orders)}` : 'Pré-pedidos'"
                                    :classes="{ active: is_open('PRE-ORDERS'), fixed: true }"
                                    @click="open('PRE-ORDERS')"
                                ></cc-button>
                                <cc-button
                                    :content="can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pedidos: ${to_currency(total_orders)}` : 'Pedidos'"
                                    :classes="{ active: is_open('ORDERS'), fixed: true }"
                                    @click="open('ORDERS')"
                                ></cc-button>
                                <div class="col-12 mt-1 align-items-center d-flex consent" v-if="is_open('PRE-ORDERS') && can('REQUEST', 'READ_ALL_SUBREQUESTS')">
                                    <toggle-button
                                        v-model="VIEW"
                                        :color="'#ff7110'"
                                        :height="30"/>
                                    <label class="toggle-label">{{ VIEW ? 'Por Requisitante' : 'Por Fornecedor' }}</label>
                                </div>
                            </div>
                            <div class="actions">
                                <cc-button
                                    :content="'Voltar'"
                                    :classes="'fixed theme-1'"
                                    :icon="'fa fa-arrow-left'"
                                    :link="'/cliente/monitores/cotacoes'"
                                ></cc-button>
                                <cc-button
                                    v-if="is_open('PRODUCTS')"
                                    :content="'Imprimir'"
                                    :classes="'fixed secondary'"
                                    :icon="'fa fa-print'"
                                    @click="printComparativo()"
                                ></cc-button>

                                <cc-button
                                    :content="freezed ? 'Congelada' : 'Congelar'"
                                    :icon="freezed ? 'fas fa-lock' : 'fas fa-lock-open'"
                                    :classes="{ freeze: true, freezed: freezed, fixed: true }"
                                    v-if="can_freeze()"
                                    @click="freeze()"
                                ></cc-button>

                                <cc-button
                                    :content="'Não digitados'"
                                    :icon="'fa fa-external-link'"
                                    :classes="'fixed'"
                                    :link="{ name: 'nao-digitados-cotacao', params: { id: $route.params.id } }"
                                ></cc-button>

                                <cc-button
                                    v-if="freezed"
                                    :content="'Remover Vencedores'"
                                    :icon="'fa fa-trash'"
                                    :classes="'fixed'"
                                    @click="clearWinners()"
                                ></cc-button>

                                <cc-button
                                    :content="''"
                                    :icon="'fas fa-download'"
                                    :classes="'fixed'"
                                    alt="Mapa de Digitações"
                                    @click="download_report()"
                                ></cc-button>
                                <cc-button
                                    :content="''"
                                    :icon="'far fa-list-alt'"
                                    :classes="{ active: is_open('FULL_REPORT'), fixed: true }"
                                    alt="Relatorio geral"
                                    title="Relatorio geral"
                                    @click="download_general_report()"

                                ></cc-button>
                                <cc-button
                                    :content="''"
                                    :icon="'fas fa-chart-bar'"
                                    :classes="{ active: is_open('FULL_REPORT_WITH_LAST_OFFER'), fixed: true }"
                                    alt="Comparativo de Preços"
                                    title="Comparativo de Preços"
                                    @click="download_general_report_with_last_offer()"
                                ></cc-button>
                            </div>
                        </div>

                        <div class="info-and-actions not-print" v-if="is_open('PRODUCTS')">
                            <div class="info">
                                <div class="form-group w m-0 pl-2 d-flex align-items-center justify-content-center checkboxes" style="width: 280px;">
                                    <cc-checkbox :label="'Novos fornecedores'" @change="(evt) => {
                                        filters.others_only = evt.target.checked
                                        update()
                                    }" :checked="filters.others_only" />
                                </div>
                                <div class="form-group w-50 m-0 pl-2 d-flex align-items-center justify-content-center checkboxes" style="width: 280px;">
                                    <cc-checkbox
                                        @change="(evt) => update()"
                                        v-model="filters.has_observation"
                                        :label="'Itens com observações'" />
                                </div>
                                <div class="form-group w-50 m-0 pl-2 d-flex align-items-center justify-content-center checkboxes" style="width: 280px;">
                                    <cc-checkbox
                                        @change="(evt) => update()"
                                        v-model="filters.variation_price"
                                        :label="'Alta variação de Preços'" />
                                </div>
                            </div>
                        </div>

                        <cc-products
                            v-if="is_open('PRODUCTS') && can('REQUEST', 'READ_ALL_SUBREQUESTS')"
                            v-bind:products="products"
                            v-bind:sellers="sellers"
                            v-bind:wraps="wraps"
                            v-bind:is_loading="is_loading"
                            @loadProviders="loadPreOrders"
                            @reload_products="getProducts"
                        ></cc-products>
                        <cc-pre-orders
                            v-if="is_open('PRE-ORDERS') && VIEW"
                            v-bind:type="'NOT_ORDERED'"
                            v-bind:sellers="sellers"
                            v-bind:wraps="wraps"
                            v-bind:is_loading="is_loading_sellers"
                            v-bind:loadPreOrders="loadPreOrders"
                            v-bind:loadTotalOrders="loadTotalOrders"
                        ></cc-pre-orders>
                        <cc-pre-orders-by-sellers
                            v-if="is_open('PRE-ORDERS') && !VIEW"
                            v-bind:type="'NOT_ORDERED'"
                            v-bind:data="sellers"
                            v-bind:wraps="wraps"
                            v-bind:is_loading="is_loading_sellers"
                            @loadPreOrders="loadPreOrders"
                            @loadTotalOrders="loadTotalOrders"
                        />
                        <cc-pre-orders
                            v-if="is_open('ORDERS')"
                            v-bind:type="'ORDERED'"
                            v-bind:sellers="sellers"
                            v-bind:wraps="wraps"
                            v-bind:is_loading="is_loading"
                            v-bind:loadPreOrders="loadPreOrders"
                            v-bind:loadTotalOrders="loadTotalOrders"
                        ></cc-pre-orders>
                        <GeneralRequestReport v-if="is_open('FULL_REPORT') && report_data" :data="report_data" />
                        <GeneralRequestReportWithLastOffer v-if="is_open('FULL_REPORT_WITH_LAST_OFFER') && report_data_with_last_offer" :data="report_data_with_last_offer" />
                    </div>
                </div>

                <div class="footer not-print" v-bind:class="{ hidden: is_open('PRE-ORDERS') || is_open('ORDERS') }">
                    <cc-pagination
                    classes="orange"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="page"
                    v-on:change="updatePage($event)"
                    ></cc-pagination>
                </div>
                </div>
            </div>

            <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="fas fa-times"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Produtos não digitados</h4>
                        </div>
                        <div class="modal-body not-typed">
                            <div class="filters">
                                <div class="filter">
                                <label>
                                    <input
                                    v-model="notTypedFilter"
                                    type="radio"
                                    name="notTypedFilter"
                                    value="T"
                                    checked="checked"
                                    /> Todos
                                </label>
                                </div>
                                <div class="filter">
                                <label>
                                    <input v-model="notTypedFilter" type="radio" name="notTypedFilter" value="NC" /> Apenas não comprados
                                </label>
                                </div>
                                <div class="filter">
                                <label>
                                    <input v-model="notTypedFilter" type="radio" name="notTypedFilter" value="ND" /> Apenas não digitados
                                </label>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                <thead>
                                    <tr>
                                    <th>NOME</th>
                                    <th>EAN</th>
                                    <th>COD INT.</th>
                                    <th>EMB</th>
                                    <th>QNT</th>
                                    <th>QUEM TEM</th>
                                    <th>POR QUANTO</th>
                                    <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in not_typed" v-bind:key="item.type + Math.random()">
                                    <template v-if="item.type === 'store'">
                                        <td class="products warning" colspan="8">{{item.name}}</td>
                                    </template>
                                    <template v-else>
                                        <td>{{item.pro_descricao}}</td>
                                        <td>{{item.pro_ean}}</td>
                                        <td>{{item.pro_codInterno}}</td>
                                        <td>{{item.pro_embalagem}}</td>
                                        <td>{{item.pro_isTop}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{item.pro_status}}</td>
                                    </template>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="winners-page-containear" v-on:click="closeOptionsSelect()">
        <div class="winners-page-route-select not-print">
            <router-link to="/cliente/monitores/cotacoes" class="winners-page-route-select-text">
                Home
            </router-link>
            <span class="material-icons winners-page-route-select-icon">play_arrow</span>
            <div class="winners-page-route-select-text">
                Comparativo de Preços
            </div>
        </div>
        <div class="winners-page-title not-print"> Comparativo de Preços</div>
        <div class="PRINT-title winners-page-title">Comparativo de Preços <br/> Cotação #{{quotationId}}</div>
        <div class="winners-page-buttons-container not-print">
            <div class="winners-page-buttons-1">
                <div :class="isActiveTab('PRODUCTS')" class="button-containear"  v-on:click="setProducts">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">inventory_2</span>
                    <div class="button-text">Produtos</div>
                </div>
                <div v-if="total_pre_orders > 0" :class="isActiveTab('PRE-ORDERS')" class="button-containear winners-page-buttons-1-mobile winners-page-button-1"  v-on:click="setPreOrders">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">reorder</span>
                    <div class="button-text">{{can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pré-pedidos: ${to_currency(total_pre_orders)}` : 'Pré-pedidos'}}</div>
                </div>
                <div :class="isActiveTab('ORDERS')" class="button-containear winners-page-buttons-1-mobile winners-page-button-1"  v-on:click="setOrders">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">local_shipping</span>
                    <div class="button-text">{{can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pedidos: ${to_currency(total_orders)}` : 'Pedidos'}}</div>
                </div>

            </div>
            <div class="winners-page-buttons-2">
                <StandardButton class="mobile-button" :action="goBack" :iconName="'arrow_back'" :text="'Voltar'"/>
                <StandardButton class="mobile-button" :action="printComparativo" :iconName="'print'" :text="'Imprimir'"/>
                <StandardButton class="mobile-button" :action="sendNotListed" :iconName="'do_not_disturb_on'" :text="'Não Digitados'"/>
                <StandardButton class="mobile-button" v-if="can_freeze()" :action="freeze" :iconName="freezed ? 'lock' : 'lock_open'" :text="freezed ? 'Congelada' : 'Congelar'"/>
                <div class="mobile-button" v-on:click.stop="() => {}">
                    <StandardButton class="mobile-button" :action="openOptionsSelect" :iconName="'expand_more'" :text="'Outras Funções'"/>
                    <div v-if="showOptionSelect" class="winners-page-options-select">
                        <div v-on:click="dowloadReport()" class="winners-page-options-select-option">Comparativo excel</div>
                        <div v-on:click="generalReport()" class="winners-page-options-select-option">Relatorio geral</div>
                        <div v-on:click="prices()" class="winners-page-options-select-option">Comparativos de preços</div>
                        <div v-if="freezed" v-on:click="clearWinners()" class="winners-page-options-select-option">Remover Vencedores</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_open('PRODUCTS')" class="winners-page-filters-container not-print">
            <StandardInputAuto class="desktop" :action="setDescription" :title="'Descrição do Produto'" :width="'30%'" :type="'text'"/>
            <StandardInputAuto class="desktop" :action="setSuplier" :title="'Fornecedor'" :width="'30%'" :type="'select'" :placeholder="'Selecione o fornecedor'" :list="filter_by_providers_options"/>
            <StandardInputAuto class="desktop" :action="setCategory" :title="'Categoria'" :width="'30%'" :type="'select'" :placeholder="'Selecione a categoria'" :list="filter_by_category_options"/>

            <StandardInputAuto class="mobile" :action="setDescription" :title="'Descrição do Produto'" :width="'100%'" :type="'text'"/>
            <StandardInputAuto class="mobile" :action="setSuplier" :title="'Fornecedor'" :width="'100%'" :type="'select'" :placeholder="'Selecione o fornecedor'" :list="filter_by_providers_options"/>
            <StandardInputAuto class="mobile" :action="setCategory" :title="'Categoria'" :width="'100%'" :type="'select'" :placeholder="'Selecione a categoria'" :list="filter_by_category_options"/>
        </div>
        <div v-else class="winners-page-filters-container winners-page-filters-preorders-container">
            <StandardInputAuto class="desktop" :action="setSeller" :title="'Empresas'" :width="'30%'" :type="'select'" :placeholder="'Selecione a empresa'" :list="filter_by_sellers_options"/>
            <StandardInputAuto class="mobile" :action="setSeller" :title="'Empresas'" :width="'100%'" :type="'select'" :placeholder="'Selecione a empresa'" :list="filter_by_sellers_options"/>
            <div class="winners-page-filters-preorders-icon-container" v-if="is_open('PRE-ORDERS') && can('REQUEST', 'READ_ALL_SUBREQUESTS')">
                <toggle-button
                    v-model="VIEW"
                    :color="'#ff7110'"
                    :height="30"/>
                <label class="toggle-label">{{ VIEW ? 'Por Requisitante' : 'Por Fornecedor' }}</label>
            </div>
        </div>
        <div v-if="is_open('PRODUCTS')" class="winners-page-selects-container not-print">
                <span v-if="filters.others_only" v-on:click="filters.others_only = false; update()" class="material-icons-outlined checkbox-icon">check_box</span>
                <span v-else v-on:click="filters.others_only = true; update()" class="material-icons-outlined checkbox-icon">check_box_outline_blank</span>
                <div>Novos fornecedores</div>
                <span v-if="filters.has_observation" v-on:click="filters.has_observation = false; update()" class="material-icons-outlined checkbox-icon">check_box</span>
                <span v-else v-on:click="filters.has_observation = true; update()" class="material-icons-outlined checkbox-icon">check_box_outline_blank</span>
                <div>Itens com observações</div>
                <span v-if="filters.variation_price" v-on:click="filters.variation_price = false; update()" class="material-icons-outlined checkbox-icon">check_box</span>
                <span v-else v-on:click="filters.variation_price = true; update()" class="material-icons-outlined checkbox-icon">check_box_outline_blank</span>
                <div>Alta variação de Preços</div>
        </div>
        <!-- <div v-if="is_loading" class="loader-containear">
            <cc-loader />
        </div> -->
        <cc-loader-full v-if="is_loading"/>
        <!-- <cc-pre-orders
            v-else-if="is_open('ORDERS')"
            v-bind:type="'ORDERED'"
            v-bind:sellers="sellers"
            v-bind:wraps="wraps"
            v-bind:is_loading="is_loading"
            v-bind:loadPreOrders="loadPreOrders"
            v-bind:loadTotalOrders="loadTotalOrders"
        ></cc-pre-orders> -->
        <WinnerPostOrders
        v-if="is_open('ORDERS')"
        v-bind:type="'ORDERED'"
        v-bind:sellers="sellers"
        v-bind:wraps="wraps"
        v-bind:is_loading="is_loading"
        v-bind:loadPreOrders="loadPreOrders"
        v-bind:loadTotalOrders="loadTotalOrders"/>
        <cc-pre-orders-by-sellers
            v-else-if="is_open('PRE-ORDERS') && !VIEW"
            v-bind:type="'NOT_ORDERED'"
            v-bind:data="sellers"
            v-bind:wraps="wraps"
            v-bind:is_loading="is_loading_sellers"
            @loadPreOrders="loadPreOrders"
            @loadTotalOrders="loadTotalOrders"
        />
        <cc-pre-orders
            v-else-if="is_open('PRE-ORDERS') && VIEW"
            v-bind:type="'NOT_ORDERED'"
            v-bind:sellers="sellers"
            v-bind:wraps="wraps"
            v-bind:is_loading="is_loading_sellers"
            v-bind:loadPreOrders="loadPreOrders"
            v-bind:loadTotalOrders="loadTotalOrders"
        ></cc-pre-orders>
        <GeneralRequestReport v-else-if="is_open('FULL_REPORT') && report_data" :data="report_data" />
        <GeneralRequestReportWithLastOffer v-else-if="is_open('FULL_REPORT_WITH_LAST_OFFER') && report_data_with_last_offer" :data="report_data_with_last_offer" />
        <div v-else class="winners-page-products-container">
            <div class="winners-page-product" v-for="(product, index) in products" :key="index">
                <div class="winners-page-product-title-container">
                    <div class="winners-page-product-title-upper">
                        <div :style="product.flag_generico == 1 ? 'cursor: pointer' : ''" class="winners-page-product-title" >
                            <span class="material-icons-outlined winners-page-product-title-expand" v-on:click="toogleProduct(product)" v-if="!product.isColapse">expand_more</span>
                            <span class="material-icons-outlined winners-page-product-title-expand" v-on:click="toogleProduct(product)" style="rotate: 270deg;" v-else>expand_more</span>
                            <span v-if="irregular_amount(product)" class="material-icons-outlined" style="color: red" title="Verificar quantidades">error</span>
                            <div @click="show_modal_brands(product)" :style="product.flag_generico == 1 ? 'border-bottom: #FF7110 1px solid' : ''">{{product.descricaoProduto}}</div>
                            <div v-if="product.flag_generico == 0">
                              &nbsp;
                              <span @click="show_generic_zero_modal_brands(product)" class="material-icons-outlined settings-icon">
                                settings
                              </span>
                            </div>
                        </div>
                        <div class="winners-page-product-quantity">Quantidade:{{ product.quantidadeCotacao ? product.quantidadeCotacao : 0 }} {{ product.unidade_compra }}</div>
                    </div>
                    <div v-if="product.codEan > 500000" class="winners-page-product-title-lower">Código: {{product.codEan}}</div>
                    
                </div>
                <div v-if="product.last_purchase" class="winners-page-product-info-container">
                    <div class="winners-page-product-info-lastbuy">
                        <div>Dados da ultima compra:</div>
                    </div>
                    <div class="winners-page-product-info-lastbuy"> Fornecedor: {{ product.last_purchase.for_nome }} </div>
                    <div class="winners-page-product-info-lastbuy" v-if="product.last_purchase.pro_observacao"> Marca : {{ product.last_purchase.pro_observacao }} </div>
                    <div class="winners-page-product-info-lastbuy"> Data: {{ product.last_purchase.data_geracao | formatDateOnly }} </div>
                    <div class="winners-page-product-info-lastbuy"> Quantidade: {{ product.last_purchase.quantidade }} </div>
                    <div class="winners-page-product-info-lastbuy"> Valor: {{formatPrice( product.last_purchase.valor )}} </div>
                </div>
                <div v-if="!product.isColapse">
                    <div class="winners-page-product-info-table-header">
                        <div class="info-table-checkbox-width"></div>
                        <div class="info-table-suplier-width">Fornecedor</div>
                        <div class="info-table-brand-width">Marca</div>
                        <div class="info-table-payment-width">Forma De Pgto	</div>
                        <div class="info-table-quantity-width">QTD. EMB.</div>
                        <div class="info-table-value-width">Valor unitário</div>
                        <div class="info-table-obs-width">Obervação</div>
                    </div>
                    <div class="winners-page-product-info-table-row" :class="getSpecialBackground(idx)" v-for="(winner, idx) in product.vencedores" :key="idx">
                        <div class="info-table-checkbox-width info-table-suplier-width-colum winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile"></div>
                            <div>
                                <span :title="getDisableTitle(winner)" v-on:click.self="DisableCheckBox(winner, product)" v-if="is_winner(winner)" class="material-icons-outlined" :class="getDisableCheckBox(winner)" style="color: #FF7110; cursor: pointer; margin-right: 10px; margin-left: 1vw">check_box</span>
                                <span :title="getDisableTitle(winner)" v-on:click.self="DisableCheckBox(winner, product)" v-else class="material-icons-outlined" :class="getDisableCheckBox(winner)" style="color: #FF7110; cursor: pointer; margin-left: 1vw">check_box_outline_blank</span>
                                <img class="winners-loader" :class="{ 'hidden' : !winner.is_loading }"  src="/images/loader.svg"/>
                                <span v-if="is_winner(winner)" :class="{ 'hidden' : winner.is_loading }" @click="current_winner = winner;current_product=product;show_current_winner=true" class="material-icons-outlined" style="color: #FF7110; cursor: pointer;">shopping_cart</span>
                                <!-- <span v-if="is_winner(winner) && product.codEan > 500000" v-on:click="showProductInfo = product.codEan; buyPrice = winner.valor" class="material-icons-outlined" style="color: #FF7110; cursor: pointer; margin-left: 0.5vw;">local_offer</span> -->                                
                            </div>

                        </div>
                        <div class="info-table-suplier-width winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">Fornecedor</div>
                            {{winner.nomeFornecedor}}
                        </div>
                        <div class="info-table-brand-width  winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">Marca</div>
                            <div class="info-table-suplier-width-colum">
                                <span v-on:click="setPreferencial(winner)" v-if="winner.pro_preferencial == 1 && product.flag_generico == 1" class="material-icons-outlined suplier-fav-icon">star</span>
                                <span v-on:click="setPreferencial(winner)" v-if="winner.pro_preferencial == 0 && product.flag_generico == 1" class="material-icons-outlined suplier-fav-icon">star_outline</span>
                                {{ winner.pro_observacao }}
                            </div>
                            
                        </div>
                        <div class="info-table-payment-width winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">Forma De Pgto</div>
                            {{(winner.forma)? winner.forma: '-'}}
                        </div>
                        <!-- <div class="info-table-quantity-width">{{product.flag_generico == 1 ? winner.embalagem : product.qtd_embalagem}}</div> -->
                        <div class="info-table-quantity-width winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">QTD. EMB.</div>
                            <div>
                                <input v-if="product.flag_generico == 1" type="number" :style="checkSaved(winner, 'quantity')" :id="'winner-quantity-'+index+'-'+idx" v-on:keyup.enter="update_offer(winner, 'quantity', index, idx)" class="info-table-input" v-model.number="winner.quantidadeDigitacao"/>
                                <div v-else class="info-table-input">{{winner.embalagem}}</div>
                            </div>
                        </div>
                        <div class="info-table-value-width winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">Valor unitário</div>
                            <div>
                                <money v-bind="money" :style="checkSaved(winner, 'value')" :id="'winner-value-'+index+'-'+idx" v-on:keyup.enter.native="update_offer(winner, 'value', index, idx)" class="info-table-input" v-model.number="winner.valor"/>
                            </div>
                            
                        </div>
                        <div class="info-table-obs-width winners-mobile-row">
                            <div class="winners-page-product-info-table-header-mobile">Obervação</div>
                            <div>
                                <input class="info-table-input" :style="checkSaved(winner)" :id="'winner-obs-'+index+'-'+idx" v-on:keyup.enter="update_offer(winner, 'obs', index, idx)" type="text" v-model="winner.observacao">
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer not-print" v-bind:class="{ hidden: is_open('PRE-ORDERS') || is_open('ORDERS') }">
            <cc-pagination
            classes="orange"
            :items_by_page_value="items_by_page_value"
            :total="total_items"
            :page="page"
            v-on:change="updatePage($event)"
            ></cc-pagination>
        </div>
        <a id="download-excel" style="display:none;"></a>
        <ProductOrdersModal
            :product_id="current_product.prod_id"
            :product_name="current_product.descricaoProduto"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />


        <cc-product-sub-requests v-if="current_winner && show_current_winner"
            :salesman="current_winner"
            :quotationId="$route.params.id"
            :product="current_product"
            @close="current_winner = null;show_current_winner=false"
            :sellers="sellers" />
        <InfoPriceProductModal v-if="showProductInfo" :buyPrice="buyPrice" :apiService="infoPriceService" :productGtin="showProductInfo" :close="() => showProductInfo = undefined"/>
        <cc-product-brands
            id="modal"
            v-if="show_brands"
            :product="current_product"
            :product_pattern="product_pattern"
            @close="show_brands = false;current_product=null"
            :reloadBrands="show_modal_brands"
            :updateBrandsModal="updateBrandsModal"
        />
        <cc-product-generic-zero-brands
            id="modal"
            v-if="show_generic_zero_brands"
            :product="current_product"
            @close="show_generic_zero_brands = false;current_product=null"
        />
    </div>
</template>
<script>
import Header from "@/components/cliente/base-components/Header";
import WinnerProducts from "@/components/partials/_products";
import WinnerPreOrders from "@/components/partials/PreOrder.vue";
import WinnerPostOrders from "@/components/partials/PostOrder.vue";
import WinnerPreOrdersBySeller from "@/components/partials/_pre_orders_by_seller"
import Breadcrumb from "@/components/shared/Breadcrumb";
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component"
import RequestsService from "@/services/v2/requests.service"
import V3RequestsService from "@/services/v3/request.service"
import StorageService from '@/services/local-storage.service';
import GeneralRequestReport from '@/components/partials/_general-report-matrix.component';
import GeneralRequestReportWithLastOffer from '@/components/partials/_general-report-with-last-offers.component';
import { mapState, mapGetters } from 'vuex'
import { perm_mixin } from '@/mixins/permission.mixin'
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import ProductOrdersModal from '@/modals/products/orders/index.vue';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import InfoPriceProductModal from "@/components/shared/InfoPriceProductModal.vue";
//import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import ProductBrandsWinnersModal from '@/modals/product-brands-winners/product-brands-winners.modal';
import ProductGenericZeroBrandsModal from "@/modals/product-generic-zero-brands/product-generic-zero-brands.modal";
import InfoPriceService from "../../../services/InfoPrice";
import { Money } from "v-money";


export default {
    mixins: [loaderMixin, perm_mixin, typingRemoteRequestControlMixin ],
    components: {
        GeneralRequestReportWithLastOffer,
        GeneralRequestReport,
        ccHeader: Header,
        ccBreadcrumb: Breadcrumb,
        ccPagination: Pagination,
        ccProducts: WinnerProducts,
        ccPreOrdersBySellers: WinnerPreOrdersBySeller,
        ccPreOrders: WinnerPreOrders,
        ccButton: Button,
        StandardButton,
        StandardInputAuto,
        ProductOrdersModal,
        'cc-product-sub-requests': ProductSubRequestsModal,
        //'cc-product-brands' : ProductBrandsModal,
        'cc-product-brands': ProductBrandsWinnersModal,
        'cc-product-generic-zero-brands': ProductGenericZeroBrandsModal,
        WinnerPostOrders,
        InfoPriceProductModal,
        buyPrice: undefined,
        Money
    },
    data() {
        return {
            updateBrandsModal: 1,
            infoPriceService: new InfoPriceService(),
            report_data: null,
            report_data_with_last_offer: null,
            is_loading: true,
            is_loading_sellers: true,
            others_offers: null,
            VIEW: true,
            CONSTANTS: CONSTANTS,
            items_by_page_value: 30,
            freezed: false,
            page: 1,
            total_items: 1000,
            notTypedFilter: "T",
            not_typed: [],
            wraps: [],
            sellers: [],
            svc: new RequestsService(),
            v3_svc: new V3RequestsService(),
            service_v1: new QuotationService(),
            prod_svc: new ProductService(),
            description: "",
            print: false,
            values_filter: [],
            active_menu: "PRODUCTS",
            filters: {
                provider: null,
                category: null,
                store: null,
                has_observation: false,
                others_only: false,
                variation_price: false
            },
            money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 2,
				masked: false
			},
            page_size: 30,
            filter_by_providers_options: [ { value: "", name: "Fornecedor" } ],
            filter_by_sellers_options: [ { value: "", text: "Empresa" } ],
            filter_by_category_options: [ { value: "", name: "Todas as categorias" } ],
            filter_by_values_options: [ { value: "P", name: "Todos" }, { value: "O", name: "Observação" }],
            datePrint: new Date(),
            quotationId: [],
            products: [],
            total_pre_orders: 0,
            total_orders: 0,
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações"
                },
                {
                    name: "Cotação " + this.$route.params.id
                }
            ],
            showOptionSelect: false,
            current_product: null,
            is_product_orders_modal_open: false,
            current_winner: null,
            current_product: null,
            show_current_winner: false,
            showProductInfo: undefined,
            show_brands: false,
            show_generic_zero_brands: false,
            product_pattern: false,
            savedTimeStamp: undefined
        }
    },
    watch: {
        // filters: {
        //     handler: function(val) {
        //         this.$store.dispatch('filters/update_request_filters', { ...val, id: this.$route.params.id })
        //     },
        //     deep: true
        // },
        'VIEW' : function(new_val) {
            this.$store.dispatch('set_view', new_val)
        }
    },
    computed: {
        has_observation(){
            return this.filters.has_observation ? "O" : "P"
        },
        ...mapState([ 'user' ]),
        ...mapGetters({ CURRENT_VIEW: 'view' })
    },
    methods: {
        show_generic_zero_modal_brands(product){
            if (product.flag_generico != 0) return
            this.is_loading = true
            this.prod_svc.get_product_brands_admin(product.pro_id)
			.then((data)=>{
                this.build_products_brands(product,data.data.produtos)
                this.is_loading = false
				this.show_generic_zero_brands = true;
			})
        },
        buil_product_derivative(derivative){
            return {id: derivative.pro_id,brand: derivative.pro_observacao, pro_padrao_id: derivative.pro_padrao_id};
        },
        build_products_brands(product, brands){
            let product_brand = product;
            product_brand.id = product.pro_id;
            product_brand.name = product.descricaoProduto;
            product_brand.pro_padrao_id = product.pro_padrao_id;
            product_brand.derivatives = [];
            brands.forEach(p => {
                product_brand.derivatives.push(this.buil_product_derivative(p));
            });
            this.current_product = product_brand;
            this.updateBrandsModal++
        },
        show_modal_brands(product){
            if (product.flag_generico != 1) return
            this.is_loading = true
            this.prod_svc.get_product_brands_admin(product.pro_id)
			.then((data)=>{
                this.build_products_brands(product,data.data.produtos)
                this.is_loading = false
				this.show_brands = true;
			})
        },
        checkSaved(winner, text){
            if (text == 'quantity' && winner.isQuantitySaved) return 'background-color: lightgreen'
            else if (text == 'value' && winner.isValueSaved) return 'background-color: lightgreen'
            else if (winner.isObsSaved) return 'background-color: lightgreen'
        },
        update_offer(winner, text, indexProduct, indexWinner) {
            if (text == 'quantity') document.getElementById('winner-value-'+indexProduct+'-'+indexWinner).focus()
            else if (text == 'value') document.getElementById('winner-obs-'+indexProduct+'-'+indexWinner).focus()
            else if (document.getElementById('winner-quantity-'+indexProduct+'-'+(indexWinner+1))) document.getElementById('winner-quantity-'+indexProduct+'-'+(indexWinner+1)).focus()
            else if (document.getElementById('winner-value-'+indexProduct+'-'+(indexWinner+1))) document.getElementById('winner-value-'+indexProduct+'-'+(indexWinner+1)).focus()
            else if (document.getElementById('winner-quantity-'+(indexProduct+1)+'-'+0)) document.getElementById('winner-quantity-'+(indexProduct+1)+'-'+0).focus()
            else if (document.getElementById('winner-value-'+(indexProduct+1)+'-'+0)) document.getElementById('winner-value-'+(indexProduct+1)+'-'+0).focus()

            let data = {
                quantity: winner.quantidadeDigitacao,
                price: winner.valor,
                obs: winner.observacao
            }

            return this.v3_svc.update_offer(this.$route.params.id, winner.idVendedor, winner.pro_id, data).then(() => {
                
                if (text == 'quantity') winner.isQuantitySaved = true
                else if (text == 'value') winner.isValueSaved = true
                else winner.isObsSaved = true
                
                const notification = {
                    type: 'success',
                    message: 'Dados salvos com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                this.$forceUpdate()
            })
        },
        toogleProduct(product) {
            product.isColapse = !product.isColapse
            this.$forceUpdate()
        },
        irregular_amount(product) { return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada },
        DisableCheckBox(winner, product) {
            if (winner.qtdPedido < 1) this.toggle_winner(winner, winner.idVendedor, this.is_winner(winner), product)
        },
        getDisableTitle(winner) {
            if (winner.qtdPedido > 0) return 'Fornecedor com pedido gerado'
        },
        getDisableCheckBox(winner){ if (winner.qtdPedido > 0) return 'disabled-check-box' },
        setSeller (value) {
            this.filters.store = {value: value}
            this.update()
        },
        setPreOrders () { this.open('PRE-ORDERS') },
        formatObs (text) {
            if (text.length > 25) return text.slice(0,24) + '...'
            return text
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        toggle_winner(winner, seller_id, is_winner, product = null) {
            this.$set(winner, "is_loading", true);
            if(this.loj_id && is_winner) {
                this.update_items_quantity(winner, this.loj_id, seller_id, () => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", 0);
                    this.getProducts(this.$route.params.id)
                });
            } else {
                var quotationService = new QuotationService();
                quotationService.toggleQuotationWinner(
                    this.$route.params.id,
                    this.loj_id,
                    winner.pro_id,
                    seller_id,
                    !is_winner
                ).then(response => response.data).then(() => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", !is_winner ? 1 : 0);
                    if(this.loj_id) {
                        if(winner.vencedor_quantidade) {
                            this.current_winner = winner
                            this.current_product= product
                            this.show_current_winner = true
                        }

                    }
                    this.getProducts(this.$route.params.id, false)
                });
            }
        },
        is_winner(winner) {
            if( winner.quantidade == 0 && winner.vencedor_quantidade == 0)return false;
            return winner.vencedor_quantidade > 0;
        },
        setPreferencial(produto) {
            produto.pro_preferencial = produto.pro_preferencial == 1 ? 0 : 1;
            this.prod_svc.pin_brand({ idProduto: produto.pro_id, preferencial: produto.pro_preferencial })
            .then(() => {
                this.products.forEach(p => {
                    if (produto.produtoprincipal == p.prod_id) {
                        p.vencedores.forEach(w => {
                            if (produto.pro_id == w.pro_id) {
                                w.pro_preferencial = produto.pro_preferencial;
                            }
                        });
                    }
                });
            }).catch(function() {})
        },
        show_orders(product) {
            if(product.last_purchase) {
                this.current_product = product
                this.is_product_orders_modal_open = true
            }
        },
        setProducts () { this.open('PRODUCTS') },
        setOrders () { this.open('ORDERS') },
        isActiveTab(tab){
            if (this.active_menu == tab) return 'button-containear-active'
        },
        setCategory (value) {
            this.page = 1
            this.filters.category = { value: value }
            this.update()
        },
        setSuplier (value) {
            this.page = 1
            this.filters.provider = value
            if (value) this.filters.provider.value = value.idFornecedor
            
            this.update()
        },
        delayUpdate () {
            this.is_loading = true
            const localTime = Date.now()
            this.savedTimeStamp = localTime
            setTimeout(() => {
                if (localTime == this.savedTimeStamp) {
                    this.page = 1
                    this.update()
                }
            }, 1500);
        },
        setDescription (value) {
            this.description = value
            this.delayUpdate()
        },
        prices () {
            this.is_loading = true
            this.download_general_report_with_last_offer()
            this.showOptionSelect = false
        },
        generalReport () {
            this.is_loading = true
            this.download_general_report()
            this.showOptionSelect = false
        },
        dowloadReport () {
            this.download_report()
            this.showOptionSelect = false
        },
        openOptionsSelect () { this.showOptionSelect = true },
        closeOptionsSelect () { this.showOptionSelect = false },
        sendNotListed () { this.$router.push({ name: 'nao-digitados-cotacao', params: { id: this.$route.params.id } } ) },
        goBack () { this.$router.push('/cliente/monitores/cotacoes') },
        download_report() {
            this.svc.request_report(this.$route.params.id).then((response) => {
                var file = response.data;
                var fileURL = window.URL.createObjectURL(file);
                var el = document.getElementById("download-excel");
                el.download = `Mapa de Digitações ${this.$route.params.id}.xls`;
                el.href = fileURL;
                el.click();
                setTimeout(function() {
                window.URL.revokeObjectURL(fileURL);
                }, 100);
            })
        },
        download_general_report() {
            this.open('FULL_REPORT')
            this.v3_svc.general_request_report(this.$route.params.id).then((response) => {
                this.is_loading = false
                this.report_data = response.data
                Vue.nextTick(() => {
                    window.document.title = `cotacao-${this.$route.params.id}.pdf`
                    setTimeout(function() {
                        window.print()
                        window.document.title = 'Club da Cotação'
                    }, 500)
                })
            })
        },
        download_general_report_with_last_offer() {
            this.open('FULL_REPORT_WITH_LAST_OFFER')
            this.v3_svc.general_request_report(this.$route.params.id).then((response) => {
                this.is_loading = false
                this.report_data_with_last_offer = response.data
            })
        },
        to_currency(value) {
            return this.$options.filters.currency(value);
        },
        is_open(menu) {
            return this.active_menu == menu;
        },
        open(menu) {
            if (menu == "PRE-ORDERS") {
                if (!this.$store.state.current_user.usu_idLoja || this.$store.state.current_user.usu_acesso_comp_loja == 1) {
                    this.active_menu = menu;
                }
            } else if(menu == "PRODUCTS") {
                    this.active_menu = menu;
            } else {
                this.active_menu = menu;
            }
        },
        toggle_collapse(index) {
            this.$set(this.collapsed, index, !this.collapsed[index]);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getAllWraps() {
            this.prod_svc.getAllWrapTypes()
            .then(response => response.data)
            .then(data => {
                let wraps = [];
                if (data.embalagens) {
                    data.embalagens.map(embalagem => {
                        wraps.push({
                            value: embalagem.emb_sigla,
                            name: embalagem.emb_sigla
                        })
                    })
                }
                this.wraps = wraps;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getSeller(sellerId, providerId) {
            return this.sellers.filter(s => s.idVendedor == sellerId && s.idFornecedor == providerId)[0];
        },
        loadPreOrders(close_loader = true, callback) {

            if (close_loader)this.is_loading_sellers = true;

            return this.service_v1.getLojasFornecedores(this.$route.params.id)
            .then(response => response.data)
            .then(data => {
                if (close_loader) this.is_loading_sellers = false;

                this.filter_by_sellers_options = [
                    {
                    value: "",
                    text: "Empresa"
                    }
                ];
                this.sellers = data.vendedores;
                this.total_pre_orders = 0;
                this.total_orders = 0
                this.sellers.forEach(s => {
                    this.total_pre_orders += s.fornecedores
                        .filter(f => !f.possuiPedido)
                        .reduce((x, f) => f.valorTotal + x, 0)
                    this.total_orders += s.fornecedores
                        .filter(f => f.possuiPedido)
                        .reduce((x, f) => f.valorTotal + x, 0)
                    if (this.filters.store == null ||
                        s.loj_id == this.filters.store.value ||
                        this.filters.store.value == "" ||
                        this.filters.store.value == undefined ||
                        this.filters.store.value == null) {
                        this.$set(s, "visible", true);
                    } else {
                        this.$set(s, "visible", false);
                    }
                    if(this.can('REQUEST', 'READ_ALL_SUBREQUESTS') || s.loj_id == this.user.store_id) {
                        this.filter_by_sellers_options.push({
                            value: s.loj_id,
                            text: s.loj_descricao
                        })
                        if(!this.can('REQUEST', 'READ_ALL_SUBREQUESTS')) {
                            this.filter_by_sellers_options = [{
                                value: s.loj_id,
                                name: s.loj_descricao
                            }]
                            this.filters.store = {
                                value: s.loj_id,
                                name: s.loj_descricao
                            }
                        }
                        this.$forceUpdate()
                    }
                    if(callback)callback()
                });
            }).catch(error => {
                if (close_loader) this.is_loading_sellers = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        loadTotalOrders() {
            this.service_v1.getVendedoresPedido(this.$route.params.id)
            .then(response => response.data).then(data => {
                // this.total_orders = data.totalPedido;
            })
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getProducts(quotationId, reload = true, callback = null) {
            this.quotationId = quotationId;
            this.is_loading = reload;

            if(reload)this.products = [];

            this.cancelSearch();

            this.svc.request_products_with_offers(
                    this.$route.params.id, {
                    product_filter: this.description,
                    page: this.page,
                    page_size: this.page_size,
                    fornecedor: this.filters.provider ? this.filters.provider.value : "",
                    imprimir: this.print,
                    obs_only: this.filters.has_observation,
                    others_only: this.filters.others_only,
                    variation_only: this.filters.variation_price,
                    category_id: this.filters.category ? this.filters.category.value : "",
            }, (this.cancelSource ? this.cancelSource.token : null)).then(response => response.data).then(data => {
                for (var i = 0; i < data.produtos.length; i++) {
                    var embalagem_split = data.produtos[i].embalagem.split(" ");
                    data.produtos[i].embalagem_units = embalagem_split[0];
                    data.produtos[i].embalagem_quantity = embalagem_split[1];
                    data.produtos[i].vencedores.forEach(seller => {
                        if (!seller.quantidadeDigitacao) seller.quantidadeDigitacao = 1
                    })
                }
                this.total_items = data.total
                this.is_loading = false;
                this.products = data.produtos;
                callback && callback()
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getNotTyped() {
            var quotationService = new QuotationService();
            quotationService.getLojasNaoDigitados(this.$route.params.id)
            .then(response => response.data).then(data => {
                let tableInfo = [];
                data.lojas.map(loja => {
                    tableInfo.push({
                    type: "store",
                    name: loja.loj_descricao
                    });
                    loja.produtos.map(product => {
                    tableInfo.push(Object.assign({ type: "product" }, product));
                    });
                });
                this.not_typed = tableInfo.filter(i => {
                    this.notTypedFilter === "T" ||
                    item.type === "store" ||
                    (this.notTypedFilter === "NC" &&
                        item.type == "product" &&
                        item.pro_status === "Não Comprado") ||
                    (this.notTypedFilter === "ND" &&
                        item.type == "product" &&
                        item.pro_status === "Não Digitado");
                });
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getAllProviders() {
            var providerService = new ProviderService();
            return providerService
                .list(this.$route.params.id)
                .then(response => response.data)
                .then(data => {
                    this.filter_by_providers_options = [{text: 'todos', value: undefined}]
                    data.fornecedores.map(fornecedor => {
                        this.filter_by_providers_options.push({
                        value: fornecedor,
                        text: fornecedor.nomeFornecedor
                        });
                    });
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                })
        },
        has_others() {
            return this.svc.others_offers(this.$route.params.id)
            .then(response => response.data).then((data) =>  {
                if(data.length > 0) {
                    this.confirm_action({
                        message: "Existem novos fornecedores nesta cotação!",
                        subtitle: `Deseja visualizar suas novas ofertas?`,
                        cancel_text: 'Continuar',
                        confirm_text: 'Exibir novos!',
                        callback: () => {
                            this.filters.others_only = true
                            this.getProducts(this.$route.params.id)
                            this.$forceUpdate()
                        }
                    })
                }
            })
        },
        update() {
            this.getAllWraps();
            if(this.can('REQUEST', 'READ_ALL_SUBREQUESTS')) {
                this.getProducts(this.$route.params.id)
            } else {
                this.is_loading = false
                this.is_loading_sellers = false
                this.open('ORDERS')
            }
            this.getNotTyped(this.$route.params.id);
            this.loadPreOrders();
            this.loadTotalOrders();
            this.updateQuotationsStatusMap()
        },
        printComparativo() {
            let productsOld = this.products;
            this.page_size = 10000
            this.getProducts(this.$route.params.id, true, () => {
                setTimeout(() => {
                    let titleOld = document.title;
                    document.title = `Clube da Cotação - ${moment().format("DD/MM/YYYY HH:mm:ss")}`;
                    window.print();
                    document.title = titleOld;
                    this.products = productsOld
                    this.page_size = 30
                }, 1000);
            })
        },
        updatePage(new_page) {
            this.page = new_page;
            this.update();
        },
        updateLimit(new_limit) {
            this.changeToFirstPage();
            this.items_by_page_value = new_limit;
            this.update();
        },
        changeToFirstPage() {
            this.page = 1;
        },
        filter() {
            this.changeToFirstPage();
            this.update();
        },
        can_freeze() {
            return this.service_v1.getQuotationStatus(this.$route.params.id) != "PEDIDO GERADO";
        },
        clearWinners() {
            if (this.freezed) {
                this.$swal
                .fire({
                    title: "Esta operacao removerá toda a seleção de compra , Deseja prosseguir?",
                    text: "Sua seleção será removida.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, remover todos!"
                })
                .then(result => {
                    if (result.value) {
                    this.is_loading = true
                    this.service_v1
                        .removeWinners(this.$route.params.id)
                        .then(response => response.data)
                        .then(data => {
                            if (data.status == CONSTANTS.REQUEST_CODE_SUCCESS) {
                                this.update();
                                this.$swal.fire(
                                "Sucesso!",
                                "Operação realizada com sucesso.",
                                "success"
                                );
                            }
                            this.is_loading = false
                        })
                        .catch(error => {
                            this.is_loading = false
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                });
            }
        },
        freeze() {
            var quotationService = new QuotationService();
            if (this.freezed) {
                this.$swal
                .fire({
                    title: "Deseja descongelar essa cotação??",
                    text: "Sua contação será descongelada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, descongelar!"
                })
                .then(result => {
                    if (result.value) {
                    quotationService
                        .unfreeze(this.$route.params.id)
                        .then(response => response.data)
                        .then(data => {
                        if (data.status == CONSTANTS.REQUEST_CODE_SUCCESS) {
                            this.updateQuotationsStatusMap();
                            this.$swal.fire(
                            "Descongelada!",
                            "Cotação descongelada com sucesso.",
                            "success"
                            );
                        }
                        })
                        .catch(error => {
                        ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                });
            } else {
                this.$swal
                .fire({
                    title: "Deseja congelar essa cotação??",
                    text: "Sua contação será congelada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, congelar!"
                })
                .then(result => {
                    if (result.value) {
                    quotationService
                        .freeze(this.$route.params.id)
                        .then(response => response.data)
                        .then(data => {
                        if (data.status == CONSTANTS.REQUEST_CODE_SUCCESS) {
                            this.updateQuotationsStatusMap();
                            this.$swal.fire(
                                "Congelada!",
                                "Cotação congelada com sucesso.",
                                "success"
                            );
                        }
                        })
                        .catch(error => {
                        ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                });
            }
        },
        loadCategories() {
            this.service_v1
            .getCategoriasCotacao(this.$route.params.id)
            .then(response => response.data).then(data => {
                this.filter_by_category_options = [{text:'todas', value: undefined}]
                data.categorias.forEach(c =>
                    this.filter_by_category_options.push({
                        text: c.cat_descricao,
                        value: c.cat_id
                    })
                );
            });
        },
        updateQuotationsStatusMap() {
            // var quotationService = new QuotationService();
            // quotationService
            // .list(this.page, null, null, null, this.$route.params.id, 10)
            // .then(response => response.data)
            // .then(data => {
            //     var statusMap = {};
            //     for (var i = 0; i < data.cotacoes.data.length; i++) {
            //         statusMap[data.cotacoes.data[i].numeroCotacao] =
            //         data.cotacoes.data[i].status_nome;
            //     }
            //     quotationService.persisteQuotationStatuses(statusMap);
            //     let status = this.service_v1.getQuotationStatus(
            //         this.$route.params.id
            //     );
            //     this.freezed = status.toLowerCase() == "congelada";
            // }).catch(error => {
            //     ErrorHandlerService.handle(error, this.$store);
            // });
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                { name: "Painel de Operações", route: 'monitors-page' },
                { name: "Cotações", route: 'monitors-page' },
                { name: `#${this.$route.params.id}`, route: 'cliente-monitores-vencedores' }
            ])
        }
    },
    created() {
        this.quotationId = this.$route.params.id
        window.scrollTo(0,0)
        let status = this.service_v1.getQuotationStatus(this.$route.params.id);
        this.freezed = status != null ? status.toLowerCase() == "congelada" : false;
        if(this.CURRENT_VIEW == undefined) {
            this.VIEW = this.user.method == 'ANALISE_POR_LOJA'
            // this.$store.dispatch('set_view', this.VIEW)
        } else {
            this.VIEW = this.CURRENT_VIEW
        }
        this.getAllProviders();
        this.loadCategories();
        this.update();
        let seen_warnings = StorageService.get('seen_warnings_request_ids') || []
        if(!this.$route.query.po && !this.$route.query.o && !seen_warnings.includes(`${this.quotationId}`)) {
            this.has_others()
            seen_warnings.push(`${this.quotationId}`)
            StorageService.set("seen_warnings_request_ids", seen_warnings)
        }
        if (this.$route.query.po) {
            this.open("PRE-ORDERS");
        }
        if (this.$route.query.o) {
            this.open("ORDERS");
        }
        if(!this.can('REQUEST', 'READ_ALL_SUBREQUESTS')) {
            this.open("PRE-ORDERS")
        }
        this.update_breadcrumbs()
        // if(this.request_filters.id == this.$route.params.id) {
        //     this.filters = this.request_filters
        // }
    }
};
</script>
<style lang="scss" scoped>
@import "./winners";
.checkboxes {
    /deep/ {
        > div {
            margin-right: 10px !important;
        }
    }
}
.winners-page-containear{
    font-size: 1vw;
    padding-right: 3vw;
}
.winners-page-route-select{
    display: flex;
    align-items: center;
}
.winners-page-route-select-text{
    font-weight: 400;
    font-size: 1.25em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.winners-page-route-select-icon{
    color: #FF9954;
    margin-right: 20px;
}
.winners-page-title{
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 35px;
}
.winners-page-mobile-info{
    margin-top: 3vh;
    width: 36%;
}
.winners-page-buttons-container{
    display: flex;
    margin-top: 6vh;
    justify-content: space-between;
}
.winners-page-buttons-1{
    display: flex;
    width: 40%;
}
.winners-page-buttons-2{
    display: flex;
    width: 57%;
    justify-content: space-between;
}
.winners-page-options-select{
    position: absolute;
    margin-top: 1vh;
    padding: 0.5vw;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background-color: white;
}
.winners-page-button-1{
    margin-left: 1.5vw;
}
.winners-page-options-select-option{
    font-size: 1.25em;
    padding: 0.5vh;
    text-align: end;
    cursor: pointer;
}
.info-table-input{
    border: 1px solid lightgray;
    border-radius: 10px;
    width: 90%;
    padding: 0 1vw;
    color: #605F5F;
    &:focus{
        outline: 1px solid lightcoral;
    }
}
.winners-page-options-select-option:hover{
    background-color: #FF7110;
    border-radius: 5px;
    color: white;
}
.winners-page-filters-container{
    display: flex;
    margin-top: 5vh;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.winners-page-filters-preorders-icon-container{
    margin-left: 2vw;
}
.winners-page-filters-preorders-container{
    align-items: flex-end;
    justify-content: flex-start;
}
.winners-page-selects-container{
    display: flex;
    justify-content: space-between;
    width: 40%;
    align-items: center;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.15px;
    color: #898989;
}
.winners-page-products-container{
    margin-top: 4vh;
}
.winners-page-product-info-table-header-mobile{display: none;}
.winners-page-product{
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5vw 1vw;
    margin-bottom: 2vh;
}
.winners-page-product-title{
    font-weight: 400;
    font-size: 1.4em;
    color: #101010;
    display: flex;
    align-items: center;
}
.winners-page-product-title-container{
    background-color: #ff660026;
    border-radius: 7px;
    padding: 0.1vw 0.5vw; 
}
.winners-page-product-title-lower{
    font-weight: 400;
    font-size: 1.4em;
    color: #FF7110;
}
.winners-page-product-title-upper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.winners-page-product-quantity{
    font-weight: 500;
    font-size: 1.25em;
    color: #101010;
}
.winners-page-product-info-container{
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.25em;
    color: #606060;
}
.winners-page-product-info-lastbuy{
    display: flex;
    align-items: center;
}
.winners-page-product-info-lastbuy-icon{
    color: #17A2B8;
    margin-left: 5px;
    cursor: pointer;
}
.winners-page-product-info-table-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    display: flex;
    padding: 1vh 0;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.1em;
    color: #605F5F;
}
.PRINT-title{
    display: none;
}
.info-table-checkbox-width{ width: 10%; }
.info-table-suplier-width{ flex: 1; }
.info-table-brand-width{ width: 14%; }
.info-table-payment-width{ width: 14%; }
.info-table-quantity-width{ width: 10%; }
.info-table-value-width{ width: 14%; }
.info-table-obs-width{ width: 18%; }
.winners-page-product-info-table-row{
    display: flex;
    padding: 1vh 0;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    align-items: center;
}
.checkbox-icon{ cursor: pointer; }
.info-table-suplier-width-colum{
    display: flex;
    align-items: center;
}
.suplier-fav-icon{
    color: #FFCA2B;
    margin-right: 5px;
    cursor: pointer;
}
.page-table-line-special{  background: #F7F7F7; }
.loader-containear{
    padding-top: 10vh;
}
.toggle-label{ color: #898989; margin-left: 1vw;}
.mobile{ display: none;}
.disabled-check-box{
    cursor: default !important;
    color: lightgrey !important;
}
.button-containear{
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
}
.button-containear-active{
    background-color: #fdf0e9;
    .button-text{color: #FF7110;}
}
.winners-page-product-title-expand{
    color: #FF7110;
    font-size: 2em;
    cursor: pointer;
}
.button-text{
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
  margin-left: 1vw;
}
.mobile-button{position: relative;}
.button-containear:hover{
  background-color: #fdf0e9;
}
.button-containear:hover > .button-text{
  color: #FF7110;
}
.winners-page-buttons-1-icon{
    color: #FF7110;
}
.winners-loader{
    height: 3vh;
    margin-left: 1vw;
}

.settings-icon {
    color: #FF7110;
    margin-top: 5px;
    cursor: pointer;
}
@media only screen and (max-width: 1200px){.winners-page-containear{font-size: 1.2vw;}}
@media only screen and (max-width: 1200px){
    .winners-page-buttons-1{width: 48%;}
    .winners-page-buttons-container{ flex-direction: column;}
    .winners-page-buttons-2{ width: 70%; margin-top: 1vh;}
}
@media only screen and (max-width: 1000px){
    .winners-page-containear{font-size: 1.5vw;}
    .winners-page-mobile-info{ width: 55%;}
    .winners-page-buttons-1{
        width: 50%;
        margin-bottom: 3vh;
    }
    .winners-page-selects-container{ width: 100%;}
}
@media only screen and (max-width: 870px){
    .winners-page-containear{font-size: 1.7vw;}
    .winners-page-buttons-1{ width: 60%;}
    .winners-page-buttons-2{ width: 80%;}
}
@media only screen and (max-width: 800px){
    .mobile{display: block; margin-bottom: 2vh;}
    .desktop{display: none;}
    .winners-page-filters-container{flex-direction: column;}
    .winners-page-mobile-info{width: 70%;}
    .winners-page-buttons-1{ width: 70%;}
}
@media only screen and (max-width: 680px){
    .winners-page-buttons-2{ width: 100%;}
    .winners-page-containear{font-size: 2vw;}
    .winners-page-buttons-1{ width: 100%;}
}
@media only screen and (max-width: 580px) {
    .winners-page-containear{font-size: 2.4vw;}
    .winners-page-buttons-2{ flex-direction: column; }
    .mobile-button{margin-bottom: 1.5vh; width: 100%;}
    .winners-page-options-select{  width: 100vw;  }
    .winners-page-options-select-option{
        font-size: 2.2em;
        text-align: center;
    }
    .winners-page-product-info-table-row{
        flex-direction: column;
        gap: 1vh;
    }
    .winners-page-product-info-container{
        flex-direction: column; 
        gap: 0.5vh;
        font-size: 2em;
    }
    .winners-page-product-info-lastbuy{justify-content: center;}
    .winners-page-product-title-upper{
        font-size: 1.7em;
        flex-direction: column;
    }
    .winners-page-product-info-table-header{display: none;}
    .info-table-checkbox-width{ width: 100%; }
    .info-table-suplier-width{ flex: unset; width: 100%; }
    .info-table-brand-width{ width: 100%; }
    .info-table-payment-width{ width: 100%; }
    .info-table-quantity-width{ width: 100%; }
    .info-table-value-width{ width: 100%; }
    .info-table-obs-width{ width: 100%; }
    .winners-page-product-info-table-header-mobile{
        display: flex;
        font-size: 1.3em;
        font-weight: bold;
    }
    .winners-mobile-row{
        display: flex;
        justify-content: space-between;
    }
    .info-table-input{width: 100%;}
}
@media only screen and (max-width: 440px){
    .winners-page-containear{font-size: 3.5vw;}
    .winners-page-mobile-info{ width: 100%;}
    .winners-page-buttons-1-mobile{ width: 30%; }
}
@media only screen and (max-width: 370px){
    .winners-page-buttons-1{width: 100%;}
    .winners-page-containear{
        font-size: 3vw;
        padding:  0 15px 15px 0;
    }
}
@media print {
    .PRINT-title{
        display: block;
    }
    .page-body{margin-top: 0;}
}
</style>
