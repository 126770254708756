<style lang="scss" scoped>
    @import './product-generic-zero-brands.modal.scss';

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  width: 80vw;
  height: 105vh;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.6em;
}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.brands-modal__product-title-wrapper {
  padding: 2em;
}
.brands-modal__product-title {
  font-weight: 400;
  color: #606060;
  line-height: 22px;
  font-size: 1.2em;
}
.brands-modal__actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
}
.page-table-container {
  margin: 2em;
  max-height: 50%;
  overflow: auto;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  align-items: center;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
  text-align: left;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.brand-width {
  flex: 1;
}
.action-width {
  width: 12%;
  display: flex; 
  align-items: center;
}
.id-width {
  width: 10%;
}
.img-width {
  width: 10%;
  display: flex;
}
.delete-icon {
  color: #CB595B;
  cursor: pointer;
}
.new-brand__back-button {
  display: flex;
  justify-content: end;
}
.checkbox-icon {
  cursor: pointer;
}

/deep/ 
.products-page__input {
  padding: 25px 10px !important;
  color: #707070;
  font-size: 1.2em;
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .modal-content-container{width: 100vw;}
}
@media only screen and (max-width: 600px) {
  .page-table-content{font-size: 1.5em;}
}
@media only screen and (max-width: 450px) {
  .modal-content-container{width: 115vw;}
  .brands-modal__actions-wrapper{
    flex-direction: column;
    gap: 1vh;
  }
}
.boxes-icon {
  width: 1.3em;
  margin-left: 10px;
  cursor: pointer;
}
.buttons-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em;
}
.button-wrapper {
  margin-left: 10px
}
.new-brand__input {
  width: 30vw !important;
}
.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #505050;
}
.edit-image-icon {
  cursor: pointer;
  color: #505050;
}
.remove-icon {
  color: #CB595B;
  margin-left: 10px;
  cursor: pointer;
}
.products-page__input-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__newbrand_input {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px;
  padding: 0.8em;
  width: 30vw;
  height: 7vh;
}
.products-page__newbrand_input:focus {
  border: 1px solid #898989 !important;
}
.products-page__input-icon {
  position: relative;
  left: -35px;
  border-radius: 0px 10px 10px 0px;
  color: #8F8F8F;
  border: none;
}
.add-icon {
  position: relative;
  top: 3px;
}
.no-brand-found {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: #8F8F8F;
  font-size: 40px;
}
.upload-img-icon {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-upload > input {
  display: none;
}

.image-upload img {
  width: 40px;
  cursor: pointer;
}

@media screen and (min-width: 1300px) {
  .products-page__input-icon {
    left: -35px;
  }
}

@media screen and (max-width: 970px) {
  .products-page__inputs-container {
    flex-direction: column;
  }
  .products-page__newbrand_input {
    width: 100vw;
    font-size: 1.8em;
  }
}
@media screen and (max-width: 700px) {
  .products-page__input-title {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 500px) {
  .products-page__newbrand_input {
    width: 60vw;
    font-size: 3em;
  }
  .products-page__input-title {
    font-size: 3em;
  }
}

/deep/
.input-containear {
  width: 30vw;
}

</style>

<template>
  
  <div class="modal-mask">
    <cc-loader-full v-if="isLoading"/>
    <div class="modal-content-container">
      <div class="new-product__header-container">
        <span class="new-product__header-title">
          Produtos
        </span>
        <span @click="close()" class="material-icons new-product__closebtn-icon">
          cancel
        </span>
      </div>
      <div v-if="actionSearch" class="brands-modal__product-title-wrapper">
        <span class="brands-modal__product-title"># {{ product.name }}</span> <br> 
        <span class="brands-modal__product-title"># Pro ID: {{ product.pro_id }}</span> <br>
        <span class="brands-modal__product-title"># Pro Padrão ID: {{ product.pro_padrao_id || '-' }} {{ product.pro_descricao_padrao }}</span> <br>
        <span class="brands-modal__product-title"># Pro Padrão Principal: {{ product.pro_padrao_principal_id || '-' }} {{ product.pro_descricao_marca_padrao }}</span> <br>
      </div>
      <div v-if="actionSearch" class="brands-modal__actions-wrapper">
        <StandardInputAuto v-if="!showAddNewBrandsInput"
                           :placeholder="'Digite o produto'"
                           :title="'Buscar produtos'"
                           :action="setProductValue"
                           class="products-page__selects"
                           :type="'text'"/>
        <div v-if="showAddNewBrandsInput">
          <label class="products-page__input-title" for="">Adicionar Marca</label>
          <div class="input-group">
            <input v-model="newBrandNameValue" placeholder="Digite a marca" class="products-page__newbrand_input" type="text">
            <div class="input-group-append">
              <button class="products-page__input-icon" type="button" @click="() => { addNewBrand() }">
                <span class="material-icons-outlined add-icon">
                  add
                </span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="showBrandsTable" class="new-brand__back-button">
          <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => { closeProductsBrands() }"/>
        </div>
        <div v-if="pro_padrao_is_null">
          <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => { close() }"/>
        </div>
      </div>
      <cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
      <div v-if="!loading && !showBrandsTable" class="page-table-container">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text id-width">ID</div>
            <div class="page-table-header-text brand-width">Produto</div>
            <div class="page-table-header-text action-width">Ações</div>
          </div>
          <div v-for="(product, idx) in productsList" 
               :key="idx"
               :class="getSpecialBackground(idx)">
            <div class="page-table-row">
              <div class="page-table-desc-column id-width">{{ product.id || '-' }}</div>
              <div class="page-table-desc-column brand-width">{{ product.name }}</div>
            <div class="page-table-header-text action-width">
              <div style="display: flex; align-items: center;">
                <span v-if="product.isChecked"
                      @click="removeProductCodes(product)"
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;">
                  check_box
                </span>
                <span v-else
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;"
                      @click="storeProductCodes(product)">
                  check_box_outline_blank
                </span>
                <div v-if="!pro_padrao_is_null">
                  <img class="boxes-icon" @click="showProductBrands(product)" title="Ver Marcas" src="@/assets/custom-boxes-icon.png">
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading && showBrandsTable" class="page-table-container">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text id-width">Prod ID</div>
            <div class="page-table-header-text brand-width">Marca</div>
            <div class="page-table-header-text action-width">Ações</div>
          </div>
          <div v-if="brandsList.length == 0" class="no-brand-found">Nenhuma marca encontrada</div>
          <div v-for="(product, idx) in brandsList" 
               :key="idx"
               :class="getSpecialBackground(idx)">
            <div class="page-table-row">
              <div class="page-table-desc-column id-width">{{ product.id || '-' }}</div>
              <div class="page-table-desc-column brand-width">{{ product.brand }}</div>
              <div class="page-table-desc-column action-width">
                <span v-if="product.isChecked"
                      @click="removeBrandsCode(product)"
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;">
                  check_box
                </span>
                <span v-else
                      class="material-icons-outlined checkbox-icon" 
                      style="color: #ff7110;"
                      @click="storeBrandsCode(product)">
                  check_box_outline_blank
                </span>
                <span @click="showEditBrandsModal(product)" class="material-icons-outlined edit-icon">
                  edit
                </span>
                <span @click="deleteBrands(product)" class="material-icons-outlined remove-icon">
                  delete
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditGenericZeroBrandModal v-if="isEditBrandsModalOpen"
                               @close="isEditBrandsModalOpen = false" 
                               :brandId="brandProductId.id"
                               @reload="reload"/>
  </div>
</template>

<script>
import * as CONSTANTS from '@/constants/constants';
import ProductService from '@/services/v3/management/product.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardSearch from '@/components/ui/inputs/StandardSearch.vue';
import Button from '@/components/ui/buttons/StandardButton.vue';
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue';
import EditGenericZeroBrandModal from './edit-generic-zero-brand.modal';

export default {
    mixins: [ loaderMixin ],
    props: {
        product: {
            type: Object,
            required: true
        },
        pro_padrao_is_null: {
            type: Boolean,
        }
    },
    components: { 
      StandardSearch,
      Button,
      StandardInputAuto,
      EditGenericZeroBrandModal,
    },
    data() {
        return {
            svc: new ProductService(),
            modal: {
                title: `Marcas #${this.product.name}`,
                icon_title: 'fa fa-list',
                cancel_text: 'Voltar',
                subtitle: "Acompanhe as marcas disponíveis para seus produtos",
                style: {
                    width: "65%"
                }
            },
            filters: {
                name: ""
            },
            actionSearch: true,
            isLoading: false,
            loading: false,
            brands: [],
            productsList: [],
            page: 1,
            total_items: 0,
            showBrandsTable: false,
            brandsList: [],
            newProdutoPadraoValue: "",
            newProdutoPadraoPrincipalValue: "",
            showAddNewBrandsInput: false,
            isEditBrandsModalOpen: false,
            brandProductId: {
              id: undefined,
            },
            newBrandNameValue: null,
            newBrandNameIdValue: null,
            productIdName: 'Nome do produto do ID',
            selectedProduct: undefined,
            fu: '',
            imageURL: "",
        }
    },
    methods: {
      selectProductNameById() {
        this.productsList.filter((productId) => {
          if (productId.id == this.product.pro_padrao_id) {
            this.productIdName = productId.name
          }
        })
      },
      setNewProdutoPadraoValue(newProductId) {
        this.product.pro_padrao_id = newProductId.id
      },
      setNewProdutoPadraoName(newProductName) {
        this.product.pro_descricao_padrao = newProductName.name
      },
      setNewProdutoPadraoPrincipalValue(newBrandId) {
        this.product.pro_padrao_principal_id = newBrandId.id
      },
      setNewProdutoPadraoPrincipalName(newBrandName) {
        this.product.pro_descricao_marca_padrao = newBrandName.brand
      },
      showEditBrandsModal(brand) {
        this.brandProductId.id = brand.id
        this.isEditBrandsModalOpen = true;
      },
      addNewBrand() {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
        const params = {
          tbl_padrao: 1,
          name: this.newBrandNameValue
        }
        axios
          .post(`${CONSTANTS.API_V3_URL}/admin/management/products/${this.newBrandNameIdValue}/brands`, params, config)
          .then(data => {
            this.$swal.fire('Configurações Salvas')
            this.brandsList.push(data.data);
            this.newBrandNameValue = null
            this.reload()
        });
      },
      reload(page = this.page) {
        this.loading = true
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            name: this.filters.name,
            page: page,
          }
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/management/products?city=&fu=&start_at=&page=1&filter=1`, config)
          .then(data => {
            this.productsList = data.data.data
            this.total_items = data.data.length
            this.loading = false
            this.selectProductNameById()
            
            this.productsList.forEach((data) => {
              if (data.id == this.selectedProduct.id) {
                this.selectedProduct = data
              }
            })
        
             this.showProductBrands(this.selectedProduct)
             this.$forceUpdate()
          });
       },
      updateProPadraoCode(product) {
        this.confirm_action({
          message: `Deseja atualizar o Pro Padrão do produto ${this.product.descricaoProduto} para ${product.id}?`,
          callback: () => {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
            const params = {
              pro_id: this.product.id,
              pro_padrao_id: this.newProdutoPadraoValue
            }
            axios
              .put(`${CONSTANTS.API_V3_URL}/admin/products/`, params, config)
              .then(res => {
                this.$swal.fire('Configurações Salvas')
                this.setNewProdutoPadraoValue(product)
                this.setNewProdutoPadraoName(product)
                this.close()
            });
          }
        })
      },
      updateProPadraoPrincipalCode(brand) {
        this.confirm_action({
          message: `Deseja atualizar o Pro Padrão Principal do produto ${this.product.descricaoProduto} para ${brand.id}?`,
          callback: () => {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
            const params = {
              pro_id: this.product.id,
              Pro_padrao_principal_id: this.newProdutoPadraoPrincipalValue
            }
          axios
            .put(`${CONSTANTS.API_V3_URL}/admin/products/`, params, config)
            .then(res => {
              this.$swal.fire('Configurações Salvas')
              this.setNewProdutoPadraoPrincipalValue(brand)
              this.setNewProdutoPadraoPrincipalName(brand)
            });
          }
        })
      },
      deleteBrands(brand) {
        this.confirm_action({
          message: `Deseja remover a marca ${brand.brand}?`,
          callback: () => {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
          axios
            .delete(`${CONSTANTS.API_V3_URL}/admin/management/products/deleteV2/${brand.id}`, config)
            .then(res => {
              this.$swal.fire('Configurações Salvas')
              this.reload()
              this.$forceUpdate()
            }).catch(err => {
              this.$swal('Não foi possível deletar esta marca pois está vinculada a um ou mais produtos!')
            })
          }
        })
      },
      showProductBrands(brands) {
        this.selectedProduct = brands
        
        this.newBrandNameIdValue = brands.id
        this.brandsList = brands.derivatives
        this.showAddNewBrandsInput = true

        this.productsList.forEach(product => {
          product.isChecked = false
        })

        this.showBrandsTable = true;
      },
      closeProductsBrands() {
        this.brandsList.forEach(brand => {
          brand.isChecked = false
        })
        this.showBrandsTable = false
        this.showAddNewBrandsInput = false
      },
      storeBrandsCode(brand) {
        this.brandsList.forEach(brand => brand.isChecked = false)

        this.updateProPadraoPrincipalCode(brand)

        if(brand.isChecked == undefined || brand.isChecked == false) {
          brand.isChecked = true
          this.newProdutoPadraoPrincipalValue = brand.id
        }
        brand.isChecked = true
        this.$forceUpdate()
      },
      removeBrandsCode(brand) {
        brand.isChecked = false
        this.$forceUpdate()
      },
      storeProductCodes(product) {
        this.productsList.forEach(product => product.isChecked = false)

        this.updateProPadraoCode(product)

        if(product.isChecked == undefined || product.isChecked == false) {
          product.isChecked = true
          this.newProdutoPadraoValue = product.id
        }
        product.isChecked = true
        this.$forceUpdate()
      },
      removeProductCodes(product) {
        product.isChecked = false
        this.$forceUpdate()
      },
      setProductValue(value){
		    this.filters.name = value
        setTimeout(() => {
          this.getProductsList(this.page)
        }, 1000);
	  	},
      getProductsList(page = this.page) {
        this.loading = true
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            name: this.filters.name,
            page: page,
          }
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/management/products?city=&fu=&start_at=&page=1&filter=1`, config)
          .then(data => {
            this.productsList = data.data.data
            this.total_items = data.data.length
            this.loading = false
            this.selectProductNameById()
        });
      },
      close() {
        if(this.actionSearch){
          this.$emit('close')
        }else{
          this.actionSearch = !this.actionSearch;
        }
      },
      getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    },
    mounted() {
      this.getProductsList()
    }
}
</script>
